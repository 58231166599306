import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';

import {PageWrapper, AssetsDownloadBlock} from '../components/templates';
import {LayoutContainer, Column} from '../components/layouts';
import {
  JourneyStages,
  PositionNav,
  JourneyDiagramSvg
} from '../components/organisms';
import {Divider} from '../components/common';
import setButtonConfig from '../helpers/setButtonConfig';
import createSlug from '../helpers/createSlug';
import {TooltipsContextProvider} from '../contexts/tooltipsContext';

/**
 * @description Page template for 'journey diagram' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Journey diagram page React element.
 */

const JourneyDiagram = ({pageContext}) => {

  const currentDiagram = 'journeyLargeImage';
  const journeyTooltips = pageContext.diagram.tooltips.filter(item => item.tooltipId.indexOf(currentDiagram) !== -1)
  const tooltips = pageContext.diagram.tooltips.filter(item => item.tooltipId.indexOf(currentDiagram) === -1)


  if (typeof window === 'undefined') { // Ensure proper server rendering.
    return null;
  }

  // TODO: Refactor how views are passed adn how activeView is selected.
  // This is defining views in owner journey. We are defining activeView as
  // a component state to be passed down the component tree, so we can
  // render correct elements.
  const views = [
    {
      value: 'owner-journey',
      label: 'Owner Journey'
    },
    {
      value: 'owner-feelings',
      label: 'Owner Feelings'
    }
  ];
  const [currentView, setCurrentView] = useState(views[0].value);

  const pageSpecies = pageContext.taxonomies.filter(tax =>
    tax.__typename === 'ContentfulSpecies')[0].speciesName;
  const pageLifestage = pageContext.taxonomies.filter(tax =>
    tax.__typename === 'ContentfulLifestage')[0].lifestageName;
  const pageWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth; // TODO: Move that to context to pass it as props to other components
  const headerHeight = pageWidth >= RCDL.config.breakpoints['xl']
    ? 120
    : 67; // TODO: Calculate header height instead of passing fixed number
  let [currentStage, setCurrentStage] = useState('');
  let [svgLoaded, setSvgLoadStatus] = useState(false);

  /**
   * @description Handles intersection event byt setting up current stage id.
   * @param {object} event Object with data passed from intersection observer.
   */
  const handleIntersection = (event) => {
    // TODO: Similar funcionality to journey diagram observer - needs moving to separate component.
    if (event.isIntersecting && event.target.id !== currentStage) {
      setCurrentStage(event.target.id);
    }
  };

  const handleViewChange = (type, tooltips) => {
    setCurrentView(type);
    if (tooltips) {
      tooltips.forEach(tooltip => {
        if (tooltip) {
          tooltip.hide();
        }
      });
    }
  };

  useEffect(() => {
    if (!svgLoaded) {
      RCDL.utilities.svgAnimation.init('[data-js-import-interactive-svg]');
      setSvgLoadStatus(true);
    }
    setCurrentStage(createSlug(pageContext.stages[0].title));
  }, [svgLoaded]);

  return (
    <TooltipsContextProvider>
      <PageWrapper pageContext={pageContext}>
        <LayoutContainer additionalClass="rc-max-width--xl" columns="five">
          <Column size="double" additionalClass=" rc-padding-x--xl--desktop rc-padding-bottom--none--mobile">
            <div className="boundry" style={{height: '90%', position: 'relative'}}>
              <Sticky
                boundaryElement={pageWidth < RCDL.config.breakpoints['xl'] ? '.rc-content--fixed-header' : '.boundry'}
                hideOnBoundaryHit={false}
                topOffset={-headerHeight}
                stickyStyle={{
                  transform: `translate3d(0,${headerHeight}px,0)`,
                  background: 'white',
                  zIndex: 3
                }}>
                <JourneyStages
                  stages={pageContext.stages}
                  title={pageContext.title}
                  currentStage={currentStage}
                  views={views}
                  setCurrentView={handleViewChange}
                  currentView={currentView}/>
              </Sticky>
            </div>
            {
              pageWidth >= RCDL.config.breakpoints['xl'] && (
                <PositionNav
                  sections={pageContext.stages}
                  currentStage={currentStage}
                  position="bottom"
                  bottomOffset={10}
                  scrollOffset={headerHeight} />
              )
            }
          </Column>
          <Column size="triple">
            <JourneyDiagramSvg
              handleIntersection={handleIntersection}
              currentStage={currentStage}
              diagramData={pageContext.diagram}
              pageWidth={pageWidth}
              species={pageSpecies}
              tooltips={tooltips}
              tooltipsJourney={journeyTooltips}
              views={views}
              setCurrentView={handleViewChange}
              currentView={currentView}/>
          </Column>
        </LayoutContainer>
        <Divider/>
        {/* This block is here for now, until we figure out how to make side nav sticky */}
        <div style={{ // This style is added to hide section navigation on scroll
          backgroundColor: 'white',
          zIndex: 1,
          position: 'relative'
        }}>
          <AssetsDownloadBlock
            image={pageContext.cardImage}
            title={`Download the ${pageLifestage} Owner Journey`}
            order='reverted'
            options={pageContext.filesToDownload}/>
        </div>
      </PageWrapper>
    </TooltipsContextProvider>
  );
};

JourneyDiagram.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default JourneyDiagram;
